
<script>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';


export default {
    components: {
        Form,
        Field,
    },
    data() {

        const schema = Yup.object().shape({
            title: Yup.string()
                .required('Title is required'),
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last name is required'),
            dob: Yup.string()
                .required('Date is required')
                .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date must be a valid date in the format YYYY-MM-DD'),
            email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),

            phone: Yup.string()
                .required('Mobile is required')
                .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid mobile number'),
                
            acceptTerms: Yup.string()
                .required('Accept Ts & Cs is required')

            
        });

        return { 
            schema, 
            items: [
    {sertype: 'Koh Samui - Koh Tao &amp; Koh Nang Yuan', qty: 0, price: 2100 },
    {sertype: 'Koh Phangan - Koh Tao &amp; Koh Nang Yuan', qty: 0, price: 2100 },
    {sertype: 'Koh Samui - Angthong National Marine Park', qty: 0, price: 2300 },
    {sertype: 'Koh Samui - Koh Phangan ', qty: 0, price: 2100 }

   
    ]
        }
    },

    computed: {
subtotalRow() {
    return this.items.map((item) => {
      return Number(item.qty * item.price)
    });
  },
  total() {
    return this.items.reduce((total, item) => {
      return total + item.qty * item.price;
    }, 0);
  }

     },
    

    methods: {
        onSubmit(values) {
            // display form values on success
            alert('SUCCESS!! :-)\n\n' + JSON.stringify(values, null, 4));
        }
    }
}
</script>

<template>
    <div class="card m-3">
        <h5 class="card-header">You are booking  Grand Sea Tour</h5>
        <div class="card-body">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="form-row">

                    <div class="form-group col-5">
                        <label>First Name</label>
                        <Field name="firstName" type="text" class="form-control" :class="{ 'is-invalid': errors.firstName }" />
                        <div class="invalid-feedback">{{errors.firstName}}</div>
                    </div>
                    <div class="form-group col-5">
                        <label>Last Name</label>
                        <Field name="lastName" type="text" class="form-control" :class="{ 'is-invalid': errors.lastName }" />
                        <div class="invalid-feedback">{{errors.lastName}}</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label>Date</label>
                        <Field name="dob" type="date" class="form-control" :class="{ 'is-invalid': errors.dob }" />
                        <div class="invalid-feedback">{{errors.dob}}</div>
                    </div>
                    <div class="form-group col">
                        <label>Email</label>
                        <Field name="email" type="text" class="form-control" :class="{ 'is-invalid': errors.email }" />
                        <div class="invalid-feedback">{{errors.email}}</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label>mobile</label>
                        <Field name="phone" type="text" class="form-control" :class="{ 'is-invalid': errors.phone }" />
                        <div class="invalid-feedback">{{errors.phone}}</div>
                    </div>
                    <div class="form-group col">       
                    </div>

                
                </div>   
                    
                    <P>Change quantity column to the number that you want to book</P>
                    

           
                <div>
                <table>
                <thead align="left">
                  <th>Item detail</th>
                  <th>Price(THB)</th>
                  <th>Quantity</th>
                  <th>Subtotal</th>
                </thead>

                <tbody>
                      <tr v-for="(item, index) in items" :key="item.id"> 
                        <td><input v-model="item.sertype" readonly size="10"></td>
                      <td ><input v-model.number="item.price" readonly size="10"></td>
                      <td ><input v-model.number="item.qty" size="10"></td>                   
                      <td ><input v-model.number="subtotalRow[index]" readonly size="10"></td>
                      </tr>
                      <tr>
                        <td><strong>Total</strong></td>
                        <td>{{total}}</td>
                      </tr>
                  </tbody>
                 </table>
               </div>
               <div class="form-group form-check">
                    <Field name="acceptTerms" type="checkbox" id="acceptTerms" value="true" class="form-check-input" :class="{ 'is-invalid': errors.acceptTerms }" />
                    <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                    <div class="invalid-feedback">{{errors.acceptTerms}}</div>
                </div>  
               <div class="form-group">
                    <button type="submit" class="btn btn-primary mr-1">Confirm</button>
                
                </div>
            </Form>
        </div>
    </div> 
    
    
    
</template>





  
  <style scoped>
  li {
    margin: 1rem 0;
    border: 1px solid #ccc;
    padding: 1rem;
  }
  
  h3 {
    margin: 0.5rem 0;
  }
  
  .role {
    border-radius: 40px;
    background-color: #ccc;
    color: #252525;
    padding: 0.25rem 1rem;
    display: inline-block;
  }
  
  .role--engineer {
    background-color: blue;
    color: white;
  }
  
  .role--consultant {
    background-color: #af003a;
    color: white;
  }
  </style> -->