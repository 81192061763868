
<script>
import * as Yup from 'yup';

export default {
    components: {
      
    },
    data() {

        const schema = Yup.object().shape({
            title: Yup.string()
                .required('Title is required'),
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last name is required'),
            dob: Yup.string()
                .required('Date is required')
                .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date must be a valid date in the format YYYY-MM-DD'),
            email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),

            phone: Yup.string()
                .required('Mobile is required')
                .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid mobile number'),
                
            acceptTerms: Yup.string()
                .required('Accept Ts & Cs is required')



            
        });

        return { 
            schema 
        }
    },
    methods: {
        onSubmit(values) {
            // display form values on success
            alert('SUCCESS!! :-)\n\n' + JSON.stringify(values, null, 4));
        }
    }
}
</script>

<template>
    
        <div><router-view></router-view></div>

    
    
</template>