<template>
    <div>
        <h1>Register</h1>
        <form class="custom-form" v-on:submit.prevent="onSubmit">
            <div class="form-group">
                <label for="username">First Name</label>
                <input v-model="first" type="text" class="form-control" id="first" placeholder="First Name" />
            </div>
            
            <div class="form-group">
                <button type="submit" class="btn btn-secondary">Register</button>
            </div>
        </form>
    </div>
</template>

<script>
   
    import axios from 'axios';

    export default {
        name: 'ReGister',
        data: function() {
            return {
                first: ''
               
            }
        },
        methods: {
            onSubmit(){
                
                axios.post("http://koh-samui.com:49146/api/booking",{
                    BookingName:this.first
                })
                .then((response)=>{
                    this.refreshData();
                    alert(response.data);
                });
            },
        }
    }
</script>