const { createApp } = require('vue');
import App from "./App.vue";
import { createRouter, createWebHistory } from 'vue-router';
import ChaDa from './components/boxing/ChaDa.vue';
import GrandSea from './components/boxing/GrandSea.vue';
import SamuiFight from './components/boxing/SamuiFight.vue';
import SantiBuri from './components/boxing/SantiBuri.vue';
import SpaTh from './components/boxing/SpaTh.vue';
import VanService from './components/boxing/VanService.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
       { path: '/ChaDa', component: ChaDa }, 
       { path: '/GrandSea', component: GrandSea },
       { path: '/samuifight', component: SamuiFight },
       { path: '/SantiBuri', component: SantiBuri },
       { path: '/SpaTh', component: SpaTh },
       { path: '/VanService', component: VanService }
    
    ]   
  });
  
  const app = createApp(App)

  app.use(router);
  
  app.mount('#app');